<template>
  <div class="h-bg-w h-padding-top-l h-margin-top-l">
    <div class="h-margin-left-l h-margin-right-l h-align-items-center h-space-between">
      <h3 class="h-h3 h-primary_shade_1">Order Info</h3>
      <div class="h-h4">
        <button type="button" class="h-btn h-btn-accent" @click="() => showEditDialog({})">
          <div class="h-align-items-center">
            <i class=" h-secondary_shade_5 healenticons-plus h-padding-right-s"></i>Add
          </div>
        </button>
      </div>
    </div>

    <div class="h1-table">
      <table ref="surgeryInfo" id="surgeryInfo" class="table dataTable checklist mt-3 xs-title-content h-table-max-50">
        <thead class="thead-light">
          <tr>
            <th style="width: 70%"><span>Care Protocol</span></th>
            <th style="width: 5%"><span>Start Date</span></th>
            <th style="width: 5%"><span>Procedure Date</span></th>
            <th style="width: 5%"><span>End Date</span></th>
            <th style="width: 1%"><span>Action</span></th>
          </tr>
        </thead>

        <tbody v-if="surgeryInfoList.length > 0">
          <tr v-for="s of surgeryInfoList" :key="s.id" class="">
            <td>{{ s.care_protocol_name }}</td>
            <td>{{ s.execution_date | formatDate }}</td>
            <td>{{ s.surgery_date | formatDate }}</td>
            <td>{{ s.discharge_date | formatDate }}</td>
            <td>
              <a href="javascript:void(0);" @click="() => showEditDialog(s)">
                <i class="fas fa-pen font-16 cursor-pointer" title="Edit"></i>
              </a>
              <a href="javascript:void(0);" @click="() => deleteItem(s.id)">
                <i class="fas fa-trash-alt h-dangerous font-16 cursor-pointer" title="Delete"></i>
              </a>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr>
            <td colspan="1000" class="h-h4 h-secondary_shade_1 h-text-center">No records</td>
          </tr>
        </tbody>
      </table>

      <EditSurgeryInfo
        ref="editDialog"
        :surgeryInfo="{}"
        :careProtocols="careProtocols"
        @onSubmit="submit" />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Vue from 'vue';
import EditSurgeryInfo from './EditSurgeryInfo';

export default {
  name: 'SurgeryInfo',

  components: {
    EditSurgeryInfo,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      surgeryInfoList: [],
      careProtocols: [],
      dataTable: null,
    };
  },

  filters: {
    formatDate(date) {
      if (!date) {
        return 'No data';
      }
      return moment(date).format('MMM D, YYYY');
    },
  },

  methods: {
    async getSurgeryInfo() {
      try {
        const resp = await this.$api.getSurgeryInfoList(this.client.id, {
          start: 0,
          length: 100,
        });
        this.surgeryInfoList = resp.data;
        if ($.fn.DataTable.isDataTable('#surgeryInfo')) {
          $('#surgeryInfo').DataTable().destroy();
        }
        this.$nextTick(() => {
          this.dataTable = $('#surgeryInfo').DataTable({
            order: [[1, 'desc']],
            dom: '<""<""tr>>',

          });
        });
      } catch (e) {
        this.surgeryInfoList = [];
      }
    },

    async getCareProtocolList() {
      try {
        const resp = await this.$api.getCareProtocols(this.client.hospital_id);
        this.careProtocols = resp.care_protocols;
      } catch (e) {
        this.careProtocols = [];
      }
    },
    showEditDialog(s) {
      this.$refs.editDialog.show(s);
    },

    async submit(data) {
      data.client_id = this.client.id;
      try {
        if (data.id) {
          await this.$api.updateSurgeryInfo(data.id, data);
        } else {
          await this.$api.createSurgeryInfo(data);
        }
        await this.getSurgeryInfo();
        this.$refs.editDialog.close();
      } catch (e) {
        $.notify('There is an error to perform action.', {
          position: 'top center',
          className: 'error',
        });
      }
    },

    deleteItem(id) {
      Vue.$confirm({
        title: 'Are you sure?',
        message: 'Do you really want to delete this Surgery Info?',
        button: {
          yes: 'Delete',
          no: 'No',
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$api.deleteSurgeryInfo(id);
            await this.getSurgeryInfo();
          }
        },
      });
    },
  },

  mounted() {
    this.getSurgeryInfo();
    this.getCareProtocolList();
  },
};
</script>
