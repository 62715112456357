<template>
  <Drawer :direction="'right'" :exist="true" ref="AppointmentDrawer">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title align:left mt-0">Appointment Details</h5>
          <div class="align:right">
            <button
              type="button"
              class="close align:right"
              aria-hidden="true"
              :disabled="loading"
              @click="close"
            >
              ×
            </button>
            <div>{{ formattedTimerValue }}</div>
          </div>
        </div>
        <div class="modal-body">
          <div v-if="!loading">
            <p class="p">
              Appointment with
              <strong>{{ this.meeting.client.name }}</strong>
              <span v-if="showEmail()">
                (<u
                  ><a
                    :href="`mailto:${this.meeting.client.email}`"
                    target="_blank"
                    >{{ this.meeting.client.email }}</a
                  ></u
                >)
              </span>
              on
              <strong>{{ getMeetingDate() }}</strong>
              at
              <strong>{{ getMeetingTime() }}</strong
              >.
            </p>
            <div class="h-ck100 h-toolbar h-drawer-maxfull h-edu">
              <CKEditor
                v-model="editorData"
                :config="editorConfig"
                :editor="editor"
              ></CKEditor>
            </div>
          </div>

          <Loader v-if="loading" />
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success waves-effect"
            @click="save"
            :disabled="loading"
          >
            Save
          </button>

          <button
            type="button"
            class="btn btn-danger waves-effect"
            @click="close"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import * as moment from 'moment';
import * as customEditor from '../../../public/assets/js/ckeditor';
import 'moment-duration-format';
import Loader from '@/components/Loader';

export default {
  data() {
    return {
      active: false,
      appointmentTimeCounter: 0,
      timerInterval: 0,
      meeting: null,
      editor: customEditor,
      editorData: '',
      editorConfig: {
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '-',
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            '|',
            'outdent',
            'indent',
            '|',
            'uploadImage',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        language: 'en',
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
      },
      loading: true,
    };
  },
  computed: {
    formattedTimerValue() {
      return moment
        .duration(this.appointmentTimeCounter, 'seconds')
        .format('mm:ss', { trim: false });
    },
  },
  methods: {
    async open(meetingId) {
      this.loading = true;
      this.$refs.AppointmentDrawer.open();
      this.meeting = await this.$api.getMeeting(meetingId);
      if (this.meeting.note) {
        this.editorData = this.meeting.note.text;
        this.appointmentTimeCounter = this.meeting.note.appointmentTimeUsed;
        this.timerInterval = setInterval(() => {
          this.appointmentTimeCounter += 1;
          if (!this.appointmentTimeCounter) clearInterval(this.timerInterval);
        }, 1000);
      } else {
        this.editorData = '';
      }
      this.$refs.AppointmentDrawer.active = true;
      this.loading = false;
    },
    async openAppointmentDetailsDrawer(meetingId) {
      if (this.$refs.AppointmentDetailsDrawer.active) {
        this.$refs.AppointmentDetailsDrawer.close();
      } else {
        this.$refs.AppointmentDetailsDrawer.open();
        this.meeting = await this.$api.getMeeting(meetingId);
        if (this.meeting.note) {
          this.editorData = this.meeting.note.text;
          this.appointmentTimeCounter = this.meeting.note.appointmentTimeUsed;
          this.timerInterval = setInterval(() => {
            this.appointmentTimeCounter += 1;
            if (!this.appointmentTimeCounter) clearInterval(this.timerInterval);
          }, 1000);
        } else {
          this.editorData = '';
        }
      }
    },
    getMeetingDate() {
      return this.$dateUtils.toLocalDate(this.meeting.start_date_time_utc);
    },

    getMeetingTime() {
      return this.$dateUtils.toLocalTime(this.meeting.start_date_time_utc);
    },

    showEmail() {
      if (this.meeting.client.email) {
        if (this.meeting.client.email.indexOf('@nomail.healent.com') === -1) {
          return true;
        }
      }
      return false;
    },
    async save() {
      this.loading = true;
      console.log('AppointmentsDrawer', this.editorData);
      await this.$api.saveMeetingNote({
        meeting_id: this.meeting.id,
        text: this.editorData,
        appointmentTimeUsed: this.appointmentTimeCounter,
      });
      $.notify('Appointment note saved.', {
        position: 'top center',
        className: 'success',
      });
      this.close(true);
    },
    close(force) {
      if (!force && this.loading) return;
      clearInterval(this.timerInterval);
      this.$refs.AppointmentDrawer.close();
      this.$refs.AppointmentDrawer.active = false;
    },
  },
  beforeDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  },

  components: {
    Loader,
    CKEditor: CKEditor.component,
  },
};
</script>
