<template>
  <div id="leftNav" class="d-none">
    <nav class="h-nav d-none">
      <ul>
        <router-link
          :to="{ name: settings.isBiller() ? 'Documents' : 'AllPatients' }"
          id="left-navigation-link>hospital>patients>all-patients"
          class="h-logo-link">
          <logo />
        </router-link>
        <li v-if="settings.canViewClients()" class="h-sidemenu-itme">
          <router-link
            data-tooltip="Patients"
            :to="{ name: 'AllPatients' }"
            id="left-navigation-link>hospital>patients>all-patients">
            <i data-tooltip="Patients" class="icon healenticons-patients"> </i>
          </router-link>
        </li>
        <li v-if="settings.canViewClients()" class="h-sidemenu-itme">
          <router-link
            data-tooltip="Patients Logs"
            :to="{ name: 'ClientsActivity' }"
            id="left-navigation-link>hospital>patients>patients-logs">
            <i data-tooltip="Patients Logs" class="icon healenticons-patients-log"> </i>
          </router-link>
        </li>
        <li v-if="settings.canViewClients()" class="h-sidemenu-itme">
          <router-link
            data-tooltip="Appointments Calendar"
            :to="{ name: 'AppointmentsCalendar' }"
            id="left-navigation-link>hospital>patients>AppointmentsCalendar">
            <i data-tooltip="Appointments Calendar" class="icon healenticons-calendar-fill"> </i>
          </router-link>
        </li>
        <li v-if="settings.canViewClients()" class="h-sidemenu-itme">
          <router-link
            data-tooltip="Messages"
            :to="{ name: 'Chat' }"
            id="left-navigation-link>hospital>chat">
            <i data-tooltip="Messages" class="icon healenticons-chat"></i>
            <span
              v-if="unreadMessagesExist"
              class="badge badge-pill badge-danger unreadMsg"
              data-tooltip="Unread message">
              0</span>
          </router-link>
        </li>
        <li v-if="settings.canViewClients()" class="h-sidemenu-itme">
          <router-link
            data-tooltip="Reports"
            :to="{ name: 'Reports' }"
            id="left-navigation-link>hospital>reports">
            <i data-tooltip="Reports" class="icon healenticons-reports"></i>
          </router-link>
        </li>
        <li class="h-sidemenu-itme">
          <router-link
            data-tooltip="Documents"
            :to="{ name: 'Documents' }"
            id="left-navigation-link>hospital>documents">
            <i data-tooltip="Documents" class="icon healenticons-document"></i>
          </router-link>
        </li>
        <li
          class="h-sidemenu-itme h-acridon-btn"
          :class="{ active: activeDocs }"
          @click="toggleActiveDocs()"
          ref="docsBtn">
          <a data-tooltip="Documentation" href="#" class="h-secondary_shade_5 h-h4">
            <i data-tooltip="Documentation" class="icon healenticons-archive"></i>
          </a>
        </li>
        <li
          v-if="settings.isSuperUser()"
          class="h-sidemenu-itme h-acridon-btn"
          :class="{ active: activeSettings }"
          @click="toggleActiveSettings()"
          ref="settingsBtn">
          <a data-tooltip="Settings" href="#" class="h-secondary_shade_5 h-h4">
            <i data-tooltip="Settings" class="icon healenticons-settings"></i>
          </a>
        </li>
      </ul>

      <ul>
        <li class="h-sidemenu-itme">
          <router-link
            :to="{ name: 'Logout' }"
            data-tooltip="Logout"
            id="left-navigation-link>hospital>logout">
            <i data-tooltip="Logout" class="icon healenticons-logout"></i>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- start mobile side menu -->
    <div>
      <div class="h-nav-overlay d-none"></div>
      <nav class="h-nav h-mobile-nav d-none">
        <ul>
          <li class="h-sidemenu-itme" data-tooltip="Healent">
            <logoMobile />
          </li>
          <li class="h-sidemenu-itme" data-tooltip="Dashboard">
            <router-link :to="{ name: 'Dashboard' }" id="left-navigation-link>hospital>dashboard">
              <i class="icon healenticons-dashboard"> </i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Dashboard</h3>
            </router-link>
          </li>
          <li class="h-sidemenu-itme h-acridon-btn1 h-dropdown" data-tooltip="Add New">
            <a href="#" class="h-secondary_shade_5 h-h4 h-fucus-height">
              <i class="icon healenticons-plus"></i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Add New</h3>
            </a>
            <div class="h-dropdown-content">
              <div id="AssessmentToolNav">
                <router-link
                  :to="{ name: 'OpioidRiskAssessment' }"
                  id="left-navigation-link>OpioidRiskAssessment"
                  target="_blank">
                  <h4 class="h-secondary_shade_5 h-h4">OSORD Risk Assessment</h4>
                </router-link>
              </div>
              <div id="RPMRTMStratification">
                <router-link
                  id="RPMRTMStratificationClick"
                  data-tooltip="Alt+R"
                  target="_blank"
                  :to="{ name: 'RPMRTMStratification' }">
                  RPM/RTM Stratification
                </router-link>
              </div>
              <div>
                <router-link id="PatientClick" :to="{ name: 'AddPatient' }"> Patient </router-link>
              </div>
            </div>
          </li>
          <li
            v-if="settings.canViewClients()"
            class="h-sidemenu-itme h-patients"
            data-tooltip="Patients">
            <router-link
              :to="{ name: 'AllPatients' }"
              id="left-navigation-link>hospital>patients>all-patients">
              <i class="icon healenticons-patients"> </i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Patients</h3>
            </router-link>
          </li>
          <li class="h-sidemenu-itme" data-tooltip="Messages">
            <router-link :to="{ name: 'Chat' }" id="left-navigation-link>hospital>chat">
              <i class="icon healenticons-chat"></i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Messages</h3>
            </router-link>
          </li>
          <li class="h-sidemenu-itme" data-tooltip="Reports">
            <router-link
              :to="{ name: 'Reports' }"
              id="left-navigation-link>hospital
            >reports">
              <i class="icon healenticons-reports"></i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Reports</h3>
            </router-link>
          </li>
          <li
            v-if="settings.isSuperUser()"
            class="h-sidemenu-itme h-acridon-btn h-dropdown"
            data-tooltip="Settings">
            <a href="#" class="h-secondary_shade_5 h-h4 h-fucus-height">
              <i class="icon healenticons-settings"></i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Settings</h3>
            </a>
            <div class="h-dropdown-content h-hmobil">
              <div>
                <router-link :to="{ name: 'Profile' }" id="left-navigation-link>user>profile">
                  <h4 class="h-secondary_shade_5 h-h4">Profile</h4>
                </router-link>
              </div>
              <div v-if="settings.isSuperUser() || userCanEditHospital()">
                <router-link
                  :to="{ name: 'HospitalSettings' }"
                  id="left-navigation-link>hospital_settings">
                  <h4 class="h-secondary_shade_5 h-h4">Facility Settings</h4>
                </router-link>
              </div>
              <div v-if="settings.isSuperUser()">
                <router-link :to="{ name: 'Forms' }" id="left-navigation-link>hospital_settings">
                  <h4 class="h-secondary_shade_5 h-h4">Form Builder</h4>
                </router-link>
              </div>
              <div v-if="userCanInvite() || settings.userCanEditStaffMembers()">
                <router-link
                  :to="{ name: 'AllMembers' }"
                  id="left-navigation-link>settings>staff>all-members">
                  <h4 class="h-secondary_shade_5 h-h4">User administration</h4>
                </router-link>
              </div>
              <div>
                <router-link
                  :to="{ name: 'CareProtocolTemplates' }"
                  id="left-navigation-link>care_protocols_templates">
                  <h4 class="h-secondary_shade_5 h-h4">Care protocol templates</h4>
                </router-link>
              </div>
              <div v-if="userCanMicroprotocols()">
                <router-link
                  :to="{ name: 'Microprotocols' }"
                  id="left-navigation-link>microprotocols">
                  <h4 class="h-secondary_shade_5 h-h4">Assessments</h4>
                </router-link>
              </div>
              <div v-if="userCanLearnModule()">
                <router-link
                  :to="{ name: 'LearnModule' }"
                  id="left-navigation-link>settings>learn-module">
                  <h4 class="h-secondary_shade_5 h-h4">Learn module</h4>
                </router-link>
              </div>
              <!--               <div>
                <router-link
                  :to="{ name: 'ImportAppointments' }"
                  id="left-navigation-link>settings>import-appointments"
                >
                  <h4 class="h-secondary_shade_5 h-h4">Import appointments</h4>
                </router-link>
              </div> -->
              <div>
                <router-link
                  :to="{ name: 'ImportCSV' }"
                  id="left-navigation-link>settings>import-csv">
                  <h4 class="h-secondary_shade_5 h-h4">Import CSV</h4>
                </router-link>
              </div>

              <div>
                <router-link
                  :to="{ name: 'SerachRecipePage' }"
                  id="left-navigation-link>settings>recipe-page">
                  <h4 class="h-secondary_shade_5 h-h4">View recipes</h4>
                </router-link>
              </div>
            </div>
          </li>
        </ul>
        <ul></ul>
        <ul>
          <li class="h-sidemenu-itme" data-tooltip="Logout">
            <router-link :to="{ name: 'Logout' }" id="left-navigation-link>hospital>logout">
              <i class="icon healenticons-logout"></i>
              <h3 class="h-h3 h-secondary_shade_5 h-margin-left-s">Logout</h3>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- End mobile side menu -->
    <div class="h-settings-menu" :class="{ active: activeDocs }" ref="menuDocs">
      <div>
        <a
          href="https://docs.google.com/document/d/1C7uYe0tz1b9CejJNpOKuzPKmMaUyEN9dwQArJsmJ_G8/edit?usp=sharing"
          data-tooltip="Daily Remote Work Expectations"
          id="document 1"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">Daily Remote Work Expectations</h4>
        </a>
      </div>
      <div>
        <a
          href="https://docs.google.com/document/d/1JruqZtaRTf1fa6P-6F8dqroyiaRIDOyw0Zw0_UNq07s/edit?usp=sharing"
          data-tooltip="Nurse SOPs"
          id="document 2"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">Nurse SOPs</h4>
        </a>
      </div>
      <div>
        <a
          href="https://docs.google.com/document/d/1LByi0Pi-qyfv1Ft-98K9NeqtBEzt7zu5608HRxE0oe0/edit?usp=sharing"
          data-tooltip="On Site Coordinator SOPs"
          id="document 3"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">On Site Coordinator SOPs</h4>
        </a>
      </div>
      <div>
        <a
          href="https://docs.google.com/document/d/1rLI0Ywl9-ZVxD77yF-Nq9uRj4PGX5rWlHZurcR-CzGU/edit?usp=sharing"
          data-tooltip="Patient Communication SOP"
          id="document 4"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">Patient Communication SOP</h4>
        </a>
      </div>
      <div>
        <a
          href="https://docs.google.com/document/d/1gHamG2v7wdnmR6H19V0pcWE33HiSluBjTwm_f47wtzU/edit?usp=sharing"
          data-tooltip="Patient Engagement Best Practices"
          id="document 5"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">Patient Engagement Best Practices</h4>
        </a>
      </div>
      <div>
        <a
          href="https://docs.google.com/document/d/1WRrejNowVklQQhLm15WjPAth5AY-3qAnKdxma_xzNTI/edit?usp=sharing"
          data-tooltip="Patient Scheduling and Visit Best Practices"
          id="document 6"
          target="_blank">
          <h4 class="h-secondary_shade_5 h-h4">Patient Scheduling and Visit Best Practices</h4>
        </a>
      </div>
    </div>

    <div class="h-settings-menu" :class="{ active: activeSettings }" ref="menu">
      <div>
        <router-link :to="{ name: 'Profile' }" id="left-navigation-link>user>profile">
          <h4 class="h-secondary_shade_5 h-h4">Profile</h4>
        </router-link>
      </div>
      <div v-if="settings.isSuperUser() || userCanEditHospital()">
        <router-link :to="{ name: 'HospitalSettings' }" id="left-navigation-link>hospital_settings">
          <h4 class="h-secondary_shade_5 h-h4">Facility Settings</h4>
        </router-link>
      </div>
      <div v-if="settings.isSuperUser()">
        <router-link :to="{ name: 'Forms' }" id="left-navigation-link>hospital_settings">
          <h4 class="h-secondary_shade_5 h-h4">Form Builder</h4>
        </router-link>
      </div>
      <div v-if="userCanInvite() || settings.userCanEditStaffMembers()">
        <router-link
          :to="{ name: 'AllMembers' }"
          id="left-navigation-link>settings>staff>all-members">
          <h4 class="h-secondary_shade_5 h-h4">User administration</h4>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{ name: 'CareProtocolTemplates' }"
          id="left-navigation-link>care_protocols_templates">
          <h4 class="h-secondary_shade_5 h-h4">Care protocol templates</h4>
        </router-link>
      </div>
      <div v-if="settings.isSuperUser()">
        <router-link :to="{ name: 'PhysicalTherapy' }" id="left-navigation-link>physical_therapy">
          <h4 class="h-secondary_shade_5 h-h4">Physical Therapy</h4>
        </router-link>
      </div>
      <div v-if="settings.isSuperUser()">
        <router-link
          :to="{ name: 'AssessmentsEditor' }"
          id="left-navigation-link>assessments_editor">
          <h4 class="h-secondary_shade_5 h-h4">Edit Assessments</h4>
        </router-link>
      </div>
      <div v-if="settings.isSuperUser()">
        <router-link
          :to="{ name: 'NudgingConfiguration' }"
          id="left-navigation-link>nudging_configuration">
          <h4 class="h-secondary_shade_5 h-h4">Nudging</h4>
        </router-link>
      </div>
      <div v-if="userCanMicroprotocols()">
        <router-link :to="{ name: 'Microprotocols' }" id="left-navigation-link>microprotocols">
          <h4 class="h-secondary_shade_5 h-h4">CCM Templates</h4>
        </router-link>
      </div>
      <div v-if="userCanLearnModule()">
        <router-link :to="{ name: 'LearnModule' }" id="left-navigation-link>settings>learn-module">
          <h4 class="h-secondary_shade_5 h-h4">Learn module</h4>
        </router-link>
      </div>
      <!--       <div>
        <router-link
          :to="{ name: 'ImportAppointments' }"
          id="left-navigation-link>settings>import-appointments"
        >
          <h4 class="h-secondary_shade_5 h-h4">Import appointments</h4>
        </router-link>
      </div> -->
      <div>
        <router-link :to="{ name: 'ImportCSV' }" id="left-navigation-link>settings>import-csv">
          <h4 class="h-secondary_shade_5 h-h4">Import CSV</h4>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{ name: 'SerachRecipePage' }"
          id="left-navigation-link>settings>recipe-page">
          <h4 class="h-secondary_shade_5 h-h4">View recipes</h4>
        </router-link>
      </div>
    </div>
    <!-- side menu -->
  </div>
</template>

<style lang="stylus" scoped>
#sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 14rem;
}

.sidebar-header {
  flex: 0;
  flex-grow: 0;
}

.sidebar-menu-items {
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

<script>
import settings from '@/settings.js';
import eventBus from '../../event-bus';
import eventKeeper from '../../eventKeeper';
import Logo from './Logo';
import LogoMobile from './LogoMobile';

export default {
  data() {
    return {
      activeDocs: false,
      activeSettings: false,
      rpm_rtm_strat_visible: false,
      settings,
      unreadMessagesExist: false,
      updateInterval: null,
    };
  },

  beforeDestroy() {
    eventBus.$offOwner(this);
    eventKeeper.$offOwner(this);
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  },

  async mounted() {
    this.updateBellNotifications();
    this.updateInterval = setInterval(() => {
      this.updateBellNotifications();
    }, 30000);
    eventBus.$on(
      'resetAndCollapseSettings',
      (event) => {
        const settingsLink = $(this.$refs.sidebarSection).find('.dropdown-toggle');
        const isSettingsExpended = settingsLink.attr('aria-expanded');
        if (isSettingsExpended === 'true') {
          // .attr returns bool values as string
          settingsLink.attr('aria-expanded', false);
          settingsLink.click();
        }
      },
      this,
    );
    eventKeeper.$on(this, 'click', document, null, this.handleClickOutside);
  },

  methods: {
    async updateBellNotifications() {
      return;
      const response = await this.$api.getChatData();
      this.unreadMessagesExist = response.rooms.some(room => room.hasUnseenMessages);
    },
    userCanEditHospital() {
      return settings.hasRole('system_administrator') || settings.hasRole('nurse_administrator');
    },
    userCanInvite() {
      return settings.getInviteRoles().length !== 0;
    },
    userCanLearnModule() {
      return settings.hasRole('system_administrator') || settings.hasRole('nurse_administrator');
    },
    userCanMicroprotocols() {
      return settings.hasRole('system_administrator') || settings.hasRole('nurse_administrator');
    },
    toggleActiveSettings() {
      if (this.activeSettings === true) {
        this.activeSettings = false; // Remove the active class
      } else {
        this.activeSettings = true; // Set the active class to the clicked element
      }
    },
    toggleActiveDocs() {
      if (this.activeDocs === true) {
        this.activeDocs = false;
      } else {
        this.activeDocs = true;
      }
    },
    handleClickOutside(event) {
      const menu = this.$refs.menu;
      const menuDocs = this.$refs.menuDocs;

      const settingsBtn = this.$refs.settingsBtn;
      if (!menu?.contains(event.target) && !settingsBtn?.contains(event.target)) {
        this.activeSettings = false;
      }
      const docsBtn = this.$refs.docsBtn;
      if (!menuDocs?.contains(event.target) && !docsBtn?.contains(event.target)) {
        this.activeDocs = false;
      }
    },
  },

  components: {
    Logo,
    LogoMobile,
  },
};
</script>
