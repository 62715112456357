<template>
  <Drawer :direction="'right'" :exist="true" ref="modalDialog">
    <div class="h-drawer-max79 h-newAppointment">
      <div class="h-drawer-card h-appointment-drawer h-quick-note">
        <div class="h-drawer-card-header">
          <div class="h-main-drawer-card-header h-margin-bottom-l">
            <h3 v-if="!modeEdit" class="h-h3 h-primary_shade_1">
              {{ this.showAppointmentDateTimeFields ? "New appointment" : "Quick note" }}
            </h3>
            <h3 v-if="modeEdit" class="h-h3 h-primary_shade_1">Edit appointment</h3>
            <button
              type="button"
              class="close"
              aria-hidden="true"
              @click="close"
              :disabled="loading"
            >
              <img src="../../../public/assets/images/Collapse-drawer.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="h-drawer-card-body h-over-x-h">
          <div class="h-card-body">
            <div :class="{ 'h-d-none': loading }">
              <div class="h-w-blok-200">
                <div :class="{ 'h-d-none': modeEdit }">
                  <h3 class="h-h4 h-primary_shade_1 h-padding-bottom-m">
                    Appointment type: Patient contact
                  </h3>
                </div>

                <div
                  class="h-d-flex h-space-between"
                  v-if="modeEdit || shouldCounterBeVisibleInDrawer"
                >
                  <div>
                    <h3
                      v-if="modeEdit"
                      class="h-h3 h-primary_shade_1 h-margin-bottom-l"
                      :class="{ 'h-margin-bottom-m': client || modeEdit }"
                    >
                      Patient: {{ appointment.client.title }}
                    </h3>
                  </div>
                  <div class="h-h4">
                    <div class="h-h3 h-primary_shade_1" v-if="shouldCounterBeVisibleInDrawer">
                      Time spent: {{ formattedTimerValue }}
                    </div>
                  </div>
                </div>

                <div
                  class="h-custom-radio"
                  :class="{ 'h-margin-bottom-m': client || modeEdit, 'h-d-none': !modeEdit }"
                  v-if="showAppointmentDateTimeFields && modeEdit"
                >
                  <div class="h-custom-checkbox h-d-flex h-space-between">
                    <div
                      class="h-fix-checkbox radiochecked"
                      v-for="(option, index) in options"
                      :key="index"
                    >
                      <input
                        type="radio"
                        class="custom-control-input"
                        :value="option"
                        v-model="selectedOption"
                        :disabled="disabledOptions.includes(option)"
                        @change="handleChange"
                      />
                      <label class="custom-control-label cursor-pointer">{{ option }}</label>
                    </div>
                  </div>
                </div>

                <div class="h-margin-bottom-l" :class="{ 'h-d-none': client || modeEdit }">
                  <label class="h-label">Patient: </label>
                  <input
                    id="newAppointmentDialog-searchClient"
                    :class="{ 'is-invalid': errors.client }"
                    class="form-control h-input newAppointmentDialog-searchClient"
                    placeholder="Type to search..."
                    data-noresults-text="No patients found."
                    autocomplete="off"
                    multiple
                  />
                  <div class="invalid-feedback" v-if="errors.client">
                    {{ errors.client }}
                  </div>
                </div>

                <div
                  class="h-margin-bottom-l"
                  :class="{ 'h-margin-bottom-m': client || modeEdit }"
                  v-if="showAppointmentDateTimeFields"
                >
                  <label class="h-label">Start date: </label>
                  <InputField
                    type="date"
                    :id="'date_input_' + randomString"
                    :name="'start_date_input_' + randomString"
                    v-model="appointment.start_date"
                    :error="errors.start_date"
                    @change="onStartDateChange"
                    @blur="onStartDateLoseFocus"
                  />
                </div>

                <div
                  class="h-margin-bottom-l"
                  :class="{ 'h-margin-bottom-m': client || modeEdit }"
                  v-if="showAppointmentDateTimeFields"
                >
                  <label class="h-label">Start time: </label>
                  <InputField
                    type="time"
                    :id="'start_time_input_' + randomString"
                    :name="'start_time_input_' + randomString"
                    v-model="appointment.start_time"
                    :error="errors.start_time"
                    @change="onStartTimeChange"
                    @blur="onStartTimeLoseFocus"
                  />
                </div>

                <div
                  class="h-margin-bottom-l"
                  :class="{ 'h-margin-bottom-m': client || modeEdit }"
                  v-if="showAppointmentDateTimeFields"
                >
                  <label class="h-label">Duration, minutes: </label>
                  <InputField
                    type="number"
                    :id="'duration_number_input_' + randomString"
                    :name="'duration_number_input_' + randomString"
                    v-model="appointment.planned_duration"
                    :error="errors.planned_duration"
                    @change="errors.planned_duration = null"
                  />
                </div>

                <div
                  id="notifyPatient"
                  class="h-margin-bottom-l"
                  v-if="showAppointmentDateTimeFields && showElement"
                >
                  <InputField
                    type="checkbox"
                    id="notifyPatientCheckbox"
                    label="Notify patient"
                    class="h-fix-checkbox"
                    v-model="notifyPatientChecked"
                  />
                </div>
                <div v-if="modeEdit && !appointment.is_past_or_present" class="h-margin-bottom-l">
                  <InputField
                    type="checkbox"
                    checkboxSwitchType="success"
                    id="isCompletedCheckbox"
                    label="Is completed"
                    v-model="appointment.is_completed"
                  />
                </div>
              </div>
            </div>
            <div :class="{ 'h-modeEdit h-margin-bottom-l': modeEdit, 'h-d-none': loading }">
              <div
                class="h-ck100 h-toolbar h-drawer-maxfull h-edu"
                :class="{ 'h-editorafterhide': !showElement }"
              >
                <CKEditor v-model="editorData" :config="editorConfig" :editor="editor" />
              </div>
            </div>
            <Loader v-if="loading" />
          </div>
        </div>

        <div class="h-drawer-card-footer">
          <div style="width: calc(100% - 30rem)" class="h-space-between h-align-items-center">
            <div>
              <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="loading">
                {{ !modeEdit ? "Create" : "Update" }}
              </button>

              <button
                type="button"
                class="h-btn h-btn-dangerous h-margin-left-s"
                @click="close"
                :disabled="loading"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import * as moment from 'moment';
import InputField from '@/components/InputField';
import Loader from '@/components/Loader';
import * as customEditor from '../../../public/assets/js/ckeditor';
import 'moment-duration-format';
import eventBus from '../../event-bus';

export default {
  data() {
    return {
      loading: false,
      appointment: {
        start_date: moment().format('YYYY-MM-DD'),
        start_time: moment().format('HH:mm'),
      },
      randomString: Math.random()
        .toString(36)
        .substring(2, 8),
      options: ['Patient contact', 'Chart note'],
      selectedOption: 'Patient contact',
      disabledOptions: [],
      errors: {},
      modeEdit: false,
      currentAppointmentId: 0,
      showAppointmentDateTimeFields: true,
      editor: customEditor,
      editorData: '',
      timerInterval: null,
      appointmentTimeCounter: 0,
      shouldCounterBeVisible: true,
      appointmentType: '',
      showElement: true,
      notifyPatientChecked: false,
      editorConfig: {
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
        },
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '-',
            'fontfamily',
            'fontsize',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            '|',
            'outdent',
            'indent',
            '|',
            'uploadImage',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
          shouldNotGroupWhenFull: true,
        },
        language: 'en',
        image: {
          toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        licenseKey: '',
        wordCount: {
          onUpdate: (stats) => {
            this.charactersLength = stats.characters;
          },
        },
        running_env: null,
      },
    };
  },
  computed: {
    isDevEnvironment() {
      let isDev = false;
      if (this.running_env) {
        isDev = this.running_env.environment === 'development';
      }
      return isDev;
    },
    formattedTimerValue() {
      return moment
        .duration(this.appointmentTimeCounter, 'seconds')
        .format('mm:ss', { trim: false });
    },
    shouldCounterBeVisibleInDrawer() {
      return this.modeEdit && this.shouldCounterBeVisible;
    },
  },
  methods: {
    handleChange() {
      if (this.selectedOption === 'Patient contact') {
        this.showElement = true;
        this.notifyPatientChecked = false;
        this.appointment.notifyPatient = true;
        this.appointment.appointment_type = 'null';
      } else {
        this.showElement = false;
        this.notifyPatientChecked = false;
        this.appointment.notifyPatient = false;
        this.appointment.appointment_type = 'chart_check';
      }
    },
    onStartDateChange(event) {
      console.log('Start date input field changed:', event);
      this.errors.start_date = null;
    },
    onStartDateLoseFocus(event) {
      console.log('Start date input field lost Focused:', this.appointment.start_date);
    },

    onStartTimeChange(event) {
      console.log('Start time input field changed:', this.appointment.start_time);
      this.errors.start_time = null;
    },

    onStartTimeLoseFocus(event) {
      console.log('Start time input field lost Focused:', this.appointment.start_time);
    },

    async save() {
      this.loading = true;

      if (this.client) {
        this.appointment.client = this.client;
      }

      if (this.appointment.only_for_note) {
        const currentDate = new Date();
        this.appointment.start_date = `${currentDate.getFullYear()}-${currentDate.getMonth() +
          1}-${currentDate.getDate()}`;
        this.appointment.start_time = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
        this.appointment.planned_duration = 1;
        this.appointment.is_completed = false;
        this.appointment.notifyPatient = false;
      }
      this.appointment.note = this.editorData;

      const payload = JSON.parse(JSON.stringify(this.appointment));
      if (payload.planned_duration) {
        payload.planned_duration = parseInt(payload.planned_duration, 10);
      }

      payload.duration_minutes = Math.floor(this.appointmentTimeCounter / 60);
      payload.duration_seconds = this.appointmentTimeCounter - payload.duration_minutes * 60;

      payload.planned_duration = this.appointment.planned_duration;
      payload.notifyPatient = this.notifyPatientChecked;

      payload.zoom_utc_offset = this.$dateUtils.utcOffsetMinutes;
      payload.notifyPatient = this.notifyPatientChecked;
      if (payload.start_time && payload.start_date) {
        const startDateTimeUtc = this.$dateUtils.toUtcDatetime(
          `${payload.start_date} ${payload.start_time}`,
        );
        const timeParts = startDateTimeUtc.split(' ');
        payload.start_date = timeParts[0];
        payload.start_time = timeParts[1];
      }
      const d = new Date();
      payload.utcOffset = d.getTimezoneOffset();

      const response = await this.$api.createMeeting(payload);
      if (response.status === 'error') {
        this.loading = false;
        this.errors = response.errors;
        if (this.errors.client) {
          $('.newAppointmentDialog-searchClient').addClass('is-invalid');
        }
      } else {
        if (this.appointment.only_for_note) {
          $.notify("The note was saved inside an appointment with today's date.", {
            position: 'top center',
            className: 'success',
          });
        }
        if (this.appointment.id) {
          console.log('NewAppointmentDialog', this.editorData);
          await this.$api.saveMeetingNote({
            meeting_id: this.appointment.id,
            text: this.editorData,
            appointmentTimeUsed: 0,
          });
        }
        this.editorData = '';
        this.$emit('appointmentUpdated', response.item);
        this.close(true);
      }
    },
    async show(appointment = null) {
      this.loading = false;
      this.randomString = Math.random()
        .toString(36)
        .substring(2, 8);
      if (!appointment) {
        this.modeEdit = false;
        this.appointment.client = null;
      } else {
        this.appointment = appointment;
        this.currentAppointmentId = appointment.id;
        if (appointment.mode === 'new') {
          eventBus.$emit('newAppointmentOpen', {});
          this.modeEdit = false;
          this.appointment.is_completed = false;
          this.appointment.notifyPatient = true;
          this.notifyPatientChecked = false;
          this.appointment.is_manual = true;
          this.appointment.start_date = moment().format('YYYY-MM-DD');
          this.appointment.start_time = moment().format('HH:mm');
        } else {
          const response = await this.$api.getMeeting(appointment.id);
          if (response.status === 'fail') {
            this.loading = false;
            $.notify(
              'Appointment details can not be opened as it was created by a different user.',
              {
                position: 'top center',
                className: 'error',
              },
            );
            return;
          }
          this.meeting = response;
          this.modeEdit = true;
          if (this.meeting.appointment_type === null) {
            this.appointmentType = 'Patient contact';
          } else {
            let meetingApptCode = this.meeting.appointment_type.replace(/\s/g, '_');
            // remove empty spaces
            meetingApptCode = meetingApptCode.toLowerCase();

            let title = meetingApptCode.replace(/_/g, ' ');
            title = title.replace(/\b\w/g, l => l.toUpperCase());
            this.appointmentType = title;
          }

          if (this.meeting.appointment_type == null) {
            this.showElement = true;
            this.notifyPatientChecked = false;
            this.appointment.notifyPatient = true;
          } else {
            this.showElement = false;
            this.notifyPatientChecked = false;
            this.appointment.notifyPatient = false;
          }

          this.appointment.planned_duration = this.meeting.planned_duration;
          this.shouldCounterBeVisible =
            this.appointmentType !== 'Automatic' && !this.meeting.is_completed;
          if (this.meeting.note) {
            this.editorData = this.meeting.note.text;

            this.appointmentTimeCounter =
              this.meeting.duration_minutes * 60 + this.meeting.duration_seconds;

            if (this.timerInterval === null && this.shouldCounterBeVisibleInDrawer) {
              this.timerInterval = setInterval(() => {
                this.appointmentTimeCounter += 1;
                if (!this.appointmentTimeCounter) clearInterval(this.timerInterval);
              }, 1000);
            }
          } else {
            this.editorData = '';
          }
        }
        this.showAppointmentDateTimeFields = !appointment.only_for_note;
      }

      $('.newAppointmentDialog-searchClient')
        .val(null)
        .removeClass('is-invalid');

      this.errors = {};
      this.errors.client = null;

      this.$refs.modalDialog.open();
      this.$refs.modalDialog.active = true;
      this.loading = false;
    },
    close(force) {
      if (!force && this.loading) return;
      this.$refs.modalDialog.close();
      this.$refs.modalDialog.active = false;
    },
    getMeetingDate() {
      return this.$dateUtils.toLocalDate(this.meeting.start_date_time_utc);
    },

    getMeetingTime() {
      return this.$dateUtils.toLocalTime(this.meeting.start_date_time_utc);
    },

    showEmail() {
      if (this.meeting.client.email) {
        if (this.meeting.client.email.indexOf('@nomail.healent.com') === -1) {
          return true;
        }
      }
      return false;
    },
  },

  async mounted() {
    const $api = this.$api;

    this.running_env = await this.$api.getRunningEnviroment();

    eventBus.$on(
      'drawer-closed',
      (event) => {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      },
      this,
    );

    if (!this.client) {
      $('.newAppointmentDialog-searchClient').autoComplete({
        minLength: 2,
        events: {
          search(qry, callback, origJQElement) {
            $api.searchClient(qry).then((result) => {
              callback(result);
            });
          },
        },
      });

      $('.newAppointmentDialog-searchClient').on('autocomplete.select', (evt, item) => {
        $('.newAppointmentDialog-searchClient').removeClass('is-invalid');
        this.appointment.client = item;
        this.errors.client = null;
      });
    }
  },
  beforeDestroy() {
    eventBus.$offOwner(this);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  },
  components: {
    InputField,
    Loader,
    CKEditor: CKEditor.component,
  },

  props: {
    client: {
      type: Object,
    },
  },
};
</script>
