<template>
  <div class="h-drawer-max500 h-no-padding" v-if="assessment">
    <SlottedMessageInDrawer ref="slottedMessage" modalSize="lg" :title="assessment.title">
      <template v-slot:modal-body>
        <div class="h1-table assessmentAnswer h-padding-left-l h-padding-right-l ">
          <table
            class="dataTable h-noborder-input table h-drawer AssessmentModal h-table-d-inline "
          >
            <template v-if="clientAnswer">
              <tbody class="h-assessmentAnswer-table-body">
                <!-- Fixed Header -->
                <tr class="thead-light">
                  <td colspan="2" class="no-weight-space text-bold text-center">
                    {{ `${showDate} ${!answer ? '[SCHEDULED]' : ''}` }}
                  </td>
                </tr>
              </tbody>
              <tbody class="scrollable-tbody">
                <!-- Scrollable content -->
                <tr
                  v-for="(row, index) of Object.values(clientAnswer).sort(
                    (a, b) => a.index - b.index
                  )"
                  :key="index"
                >
                  <td class="no-weight-space h-pl-4">
                    {{ `${row.index + 1}. ${row.question}` }}
                  </td>
                  <td class="no-weight-space text-center answer">
                    <InputField
                      type="select"
                      :choices="options[row.code] || []"
                      dense
                      v-model="clientAnswer[row.code].value"
                      @input="value => onValueChange(value, row.code)"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody class="h-assessmentAnswer-table-body">
                <!-- Fixed Footer -->
                <tr class="thead-light">
                  <td class="no-weight-space text-bold">
                    {{ 'Score:' }}
                  </td>
                  <td class="no-weight-space text-bold">
                    {{ score || '[No score value]' }}
                  </td>
                </tr>
                <tr class="thead-light">
                  <td class="no-weight-space text-bold">
                    {{ 'Range:' }}
                  </td>
                  <td class="no-weight-space text-bold">
                    {{ range || '[No range value]' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </template>

      <template v-slot:modal-footer>
        <button type="button" class="h-btn h-btn-accent" @click="save" :disabled="false">
          {{ saving ? 'Saving...' : 'Save' }}
        </button>

        <button type="button" class="h-btn h-btn-dangerous h-margin-left-s" @click="close">
          Close
        </button>
      </template>
    </SlottedMessageInDrawer>
  </div>
</template>

<style lang="stylus" scoped>
.table .thead-light td {
  color: #50649c;
  background-color: #EEEFF5;
  border-color: #eaf0f7;
}
</style>

<script>
import InputField from '@/components/InputField';
import SlottedMessageInDrawer from '@/components/dialogs/SlottedMessageInDrawer';

export default {
  name: 'AssessmentAnswerDialog',
  data() {
    return {
      saving: false,
      clientAssessmentId: null,
      assessment: null,
      answer: null,
      score: null,
      showDate: null,
      clientAnswer: {},
      options: {},
      range: null,
    };
  },

  methods: {

    async show(clientAssessmentId) {
      await this.getClientAssessment(clientAssessmentId);
      this.$refs.slottedMessage.show();
    },

    async getClientAssessment(clientAssessmentId) {
      try {
        const {
          id,
          score,
          due_date: dueDate,
          submitted_on: submittedOn,
          answer,
          assessment,
        } = await this.$api.getClientAssessment(clientAssessmentId);
        this.clientAssessmentId = id;
        this.score = score;
        if (answer) {
          this.showDate = submittedOn;
        } else {
          const localSubmittedOn = new Date(submittedOn);
          this.showDate = localSubmittedOn.toLocaleDateString();
        }
        this.answer = answer;
        this.assessment = assessment;
        this.setClientAnswer();
      } catch (e) {
        // todo error catch and show error message
        console.log(e);
      }
    },

    setClientAnswer() {
      this.changing = false;
      this.clientAnswer = {};
      this.options = {};
      if (this.answer) {
        Object.keys(this.answer).forEach((key) => {
          const answer = this.assessment.questions.find(q => q.name === key);
          if (answer) {
            const a = this.answer[key];
            this.clientAnswer[key] = {
              code: a.code,
              index: a.index,
              label:
                typeof a.value === 'number' ? a.label : a.value?.label || answer.options[0].label,
              value: typeof a.value === 'number' ? a.value : a.value?.value || 0,
              question: a.question,
            };
          }
        });
      } else {
        this.assessment.questions.forEach((q, index) => {
          this.clientAnswer[q.name] = {
            code: q.name,
            index,
            label: '-',
            value: null,
            question: q.question,
          };
          this.options[q.name] = this.getOption(q.name);
        });
      }
      Object.keys(this.clientAnswer).forEach((key) => {
        this.options[key] = this.getOption(key);
      });
      this.getRange(false);
    },

    getOption(code) {
      let options = this.assessment.questions.find(q => q.name === code)?.options || [];
      options = options.map(option => ({ title: option.label, value: option.value }));
      options.unshift({ title: '-', value: null });
      return options.sort((a, b) => a.value - b.value);
    },


    onValueChange(value, key) {
      const options = this.options[key] || [];
      const option = options.find(item => item.value === Number(value));
      if (option) {
        this.clientAnswer[key].label = option.title;
        this.clientAnswer[key].value = option.value;
      }
      this.calculateScore();
      this.getRange(true);
    },

    calculateScore() {
      this.score = `${Object.values(this.clientAnswer).reduce((sum, item) => sum + item.value, 0)}`;
    },

    getRange(updated = false) {
      if (!this.answer && !updated) {
        this.range = '[No range value]';
        return;
      }
      const legend = this.assessment?.legend || [];
      const values = Object.values(this.clientAnswer).map(item => item.value);
      const min = Math.min(...values);
      const max = Math.max(...values);
      const minLegend = legend.find(item => item.value === `${min}`);
      const maxLegend = legend.find(item => item.value === `${max}`);
      const minStr = minLegend ? minLegend.description : `${min}`;
      const maxStr = maxLegend ? maxLegend.description : `${max}`;
      this.range = `${minStr} - ${maxStr}`;
    },

    close() {
      this.$refs.slottedMessage.close();
    },
    validateAnswers() {
      const isAllAnswered = Object.values(this.clientAnswer).every(answer => answer.value !== null);
      if (!isAllAnswered) {
        $.notify('Please select the answers', {
          position: 'top center',
          className: 'error',
        });
        return false;
      }
      return true;
    },

    async save() {
      if (!this.validateAnswers()) {
        return; // Stop the save process if validation fails
      }
      try {
        this.saving = true;
        await this.$api.saveClientAssessment({
          client_assessment_id: this.clientAssessmentId,
          answer: this.clientAnswer,
        });
        this.saving = false;
        await this.$emit('updatedAssessment');
        this.$refs.slottedMessage.close();
      } catch (e) {
        // todo error catch and show error message
        console.log(e);
      }
    },
  },

  props: {},

  components: {
    InputField,
    SlottedMessageInDrawer,
  },
};
</script>
